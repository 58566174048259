<template>
    <div class="details">
        <div class="banner">
            <img :src="obj[type].img" alt="">
            <div class="text" :style="textLeft">
                <h2>{{obj[type].title}}</h2>
                <p>{{obj[type].p}}</p>
            </div>
        </div>
        <div class="bannerH5" :style="obj[type].name=='助力高考'?'background: url('+duty_img_banner3+')  0% 0% / 100% 100% no-repeat;':'background: url('+duty_img_banner2+')  0% 0% / 100% 100% no-repeat;'">
            <h3>{{obj[type].title}}</h3>
            <h5>{{obj[type].p}}</h5>
        </div>

        <div class="tap">
            <div class="main">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/enterprise' }">企业责任</el-breadcrumb-item>
                    <el-breadcrumb-item>{{obj[type].name}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="con">
            <div class="main">
                <div class="title">{{obj[type].con_tit}}</div>
                <p class="p">{{obj[type].con_p}}</p>
                <p class="H5p">{{obj[type].H5_con_p1}}</p>
                <p class="H5p">{{obj[type].H5_con_p2}}</p>
                <video-player class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="obj[type].playerOptions">
                </video-player>
            </div>
            <!-- 全球防疫 -->
            <div v-if="type=='one'">
                <a v-if="viewType" target="_blank" href="https://www.lifetreebooks.org.cn/">
                    <div class="herf">
                        {{obj[type].herf}} >
                    </div>
                </a>
                <a v-if="!viewType" target="_blank" href="https://lifetreebooks.org.cn/pc/life/index.html">
                    <div class="herf">
                        {{obj[type].herf}} >
                    </div>
                </a>
                <p class="main p">{{obj[type].con_text1}}</p> 
                <p class="main p">{{obj[type].con_text2}}</p>
                <div class="transverse">
                    <ul>
                        <li><img src="../../assets/PC/transverse1.png" class="detailsPC" alt=""><img class="detailsClass" src="../../assets/H5/detailsClass1.png" alt=""></li>
                        <li><img src="../../assets/PC/transverse2.png" class="detailsPC" alt=""><img class="detailsClass" src="../../assets/H5/detailsClass2.png" alt=""></li>
                        <li><img src="../../assets/PC/transverse3.png" class="detailsPC" alt=""><img class="detailsClass" src="../../assets/H5/detailsClass3.png" alt=""></li>
                    </ul>
                </div>
                <p class="main p">生命树童书网从发起到上线仅30天，来自全球53个国家的2万多人次点击浏览，在海内外社交媒体上获得点赞和关注。为全球抗疫童书互译共读公益项目作出重大贡献，获得了广泛好评。</p>
                <div class="number main">
                    <ul>
                        <li>
                            <div class="num">107</div>
                            <div class="text">个国家</div>
                        </li>
                        <li>
                            <div class="num">264932</div>
                            <div class="text">次浏览</div>
                            <div class="msg">（数据更新截止至：2021年7月1日）</div>
                        </li>
                        <li>
                            <div class="num">18</div>
                            <div class="text">语种</div>
                        </li>
                    </ul>
                    <div class="msgH5">（数据更新截止至：2021年7月1日）</div>
                </div>
                <p class="main p pLine">生命树童书网www.lifetreebooks.org.cn——国际儿童读物联盟（IBBY，International Board on Books for Young People）是和联合国儿童基金会和联合国教科文组织有正式谘商关系的非赢利的国际非政府组织，成立于1953年，总部在瑞士巴塞尔。IBBY宗旨是通过高品质儿童图书促进国际理解。</p>
                <!-- 媒体报道 -->
                <div class="media main">
                    <div class="title">
                        媒体报道
                    </div>
                    <ul>
                        <li>
                            <a target="_blank" href="https://xhpfmapi.zhongguowangshi.com/vh512/share/9062304?channel=weixin&from=timeline&isappinstalled=0">
                            <span>新华社</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://wap.peopleapp.com/article/rmh12539387/rmh12539387?from=timeline&isappinstalled=0">
                            <span>人民日报</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://article.xuexi.cn/articles/index.html?art_id=14809609689703461338&source=share&study_style_id=feeds_default&share_to=wx_single&study_comment_disable=0&part_id=14809609689703461338&ptype=0&item_id=14809609689703461338">
                            <span>中宣部</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://app.gmdaily.cn/as/opened/n/f85f4247bf294ca4b863bbd1197aa108">
                            <span>光明日报</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://new.qq.com/rain/a/20201020A0BZ7X00">
                            <span>腾讯新闻</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://3g.163.com/local/article/FJ8REVPU04379D76.html">
                            <span>网易新闻</span>
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- 读书 -->
                <div class="ibby main">
                    <img src="../../assets/H5/img_ibbylogo.png" class="logo" alt="">
                    <P>国际儿童读物联盟（IBBY，International Board on Books for Young People）是和联合国儿童基金会和联合国教科文组织有正式谘商关系的非赢利的国际非政府组织，成立于1953年，总部在瑞士巴塞尔。IBBY宗旨是通过高品质儿童图书促进国际理解。</P>
                    <a target="_blank" href="https://www.ibby.org/"><div class="btn">
                        了解更多
                    </div></a>
                </div>
            </div>

            <!-- 助力高考 -->
            <div v-if="type=='two'">
                <div class="main imgText">
                    <ul>
                        <li>
                            <p>{{obj[type].con_text}}</p>
                             <a target="_blank" href="http://caijing.chinadaily.com.cn/chanye/2018-06/11/content_36366447.htm">
                                <div class="btn">{{obj[type].herf}} ></div>
                             </a>
                        </li>
                        <li><img src="../../assets/PC/imgText.png" alt=""></li>
                    </ul>
                </div>
                <div class="number main">
                    <div class="title">参与数据统计</div>
                    <ul>
                        <li>
                            <div class="num">200</div>
                            <div class="text">位司机</div>
                        </li>
                        <li>
                            <div class="num">2000</div>
                            <div class="text">位考生</div>
                        </li>
                        <li>
                            <div class="num">2</div>
                            <div class="text">个城市</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import duty_img_banner2 from "../../assets/H5/duty_img_banner2.png";
import duty_img_banner3 from "../../assets/H5/duty_img_banner3.png";
export default {
    data () {
        return {
            textLeft:'left:0px',
            type:'one',
            viewType:this.$look(),
            duty_img_banner2:duty_img_banner2,
            duty_img_banner3:duty_img_banner3,
            obj:{
                one:{
                    name:'全球战疫',
                    title:'全球战疫  互译共读',
                    p:'特殊时期的特殊阅读，通过高品质儿童图书增进国际理解和友谊',
                    img:require('../../assets/PC/duty_img_banner2.png'),
                    con_tit:'全球战疫  互译共读',
                    con_p:'特殊时期的特殊阅读，通过高品质儿童图书增进国际理解和友谊',
                    H5_con_p1:'特殊时期的特殊阅读',
                    H5_con_p2:'通过高品质儿童图书增进国际理解和友谊',
                    con_text1:'在全球疫情爆发第一时间由IBBY国际儿童读物联盟发起全球互译共读公益项目之初，依蓝云科技公司第一时间响应倡议，义务担当起全球抗疫童书互译共读项目技术保障组重任。为圆满完成任务，支持特殊时期全球儿童阅读事业，依蓝云科技公司迅速在企业内组建了技术团队骨干组成的专项技术研发团队，并成立“生命树童书网网络作战指挥部”(以下简称‘指挥部’)，全面负责生命树童书网的开发和云计算方面的工作，主要集中为网站UI交互设计、网站技术研发、大数据后台管理、网站浏览数据统计与分析等，也就是通过互联网技术实现全球儿童“云阅读”。希望通过我们以及全球抗疫童书互译共读项目各专业组的共同努力，能尽快使世界数以亿计的各国儿童在生命树童书网上看到这些精心挑选和翻译的这些来自中国的优秀抗疫童书，从而有助于抚慰各国儿童心灵，帮助他们和他们的父母、老师在便捷地了解疫情共同战胜疫情的同时，帮助培养儿童阅读的兴趣，使他们成为终身阅读者和学习者，并通过这些特殊时期的特殊阅读，增进国际理解和友谊。',
                    con_text2:'指挥部在接到任务后，仅仅历时10天，就使“生命树童书网”初具雏形，又结合其他小组进度对平台进行不断优化，最终确保4月2日手机端、平板端上线，PC端也会于本月全面上线。在此同时，依蓝云还承办了“生命树童书网”全球上线发布会，在疫情期间，使近一个月内为之努力的各成员、关注此平台的各界人士“云会面”在一起，见证了这一有温度的成果。多少个日夜的研发、交互设计、后台管理，多少次的大数据分析，在短时间内实现了上百国家及破万的访问量，一时间刷爆你我朋友圈。',
                    herf:'进入生命树童书网',
                    playerOptions: {
                        //播放速度
                        playbackRates: [0.5, 1.0, 1.5, 2.0], 
                        //如果true,浏览器准备好时开始回放。
                        autoplay: false, 
                        // 默认情况下将会消除任何音频。
                        muted: true, 
                        // 导致视频一结束就重新开始。
                        loop: true, 
                        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                        preload: 'auto', 
                        language: 'zh-CN',
                        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                        aspectRatio: '16:9',
                        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                        fluid: true,
                        sources: [{
                            //类型
                            type: "video/mp4",
                            //url地址
                            src: require('../../assets/PC/d8bc67cf7e3b7b829349b060c367e0bf.mp4')
                        }],
                        //你的封面地址
                        poster: require('../../assets/PC/view3.png'), 
                        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                        notSupportedMessage: '此视频暂无法播放，请稍后再试',
                        controlBar: {
                            timeDivider: true,
                            durationDisplay: true,
                            remainingTimeDisplay: false,
                            //全屏按钮
                            fullscreenToggle: true  
                        }
                    },
                },
                two:{
                    name:'助力高考',
                    title:'县域城市  助力高考',
                    p:'高考伊始巡游出租车队伍义务接送考生，助力考生金榜题名',
                    img:require('../../assets/PC/duty_img_banner3.png'),
                    con_tit:'县域城市  助力高考',
                    con_p:'高考伊始巡游出租车队伍义务接送考生，助力考生金榜题名',
                    H5_con_p1:'高考伊始巡游出租车队伍义务接送考生',
                    H5_con_p2:'助力考生金榜题名',
                    con_text:'十年寒窗苦读，重在金榜题名时。年年有高考，今年又逢高考时。高考虽然是短暂的人生片段，却是极其重要的。它作为正规的、公平的竞技起点，是人们改变命运的支点。所以今年的6月7号、8号对于考生来说异常重要。因为其重要性，所以社会上很多个人或者公司都助力高考。有些免费为学生提供矿泉水，还有的免费为学生约车，比如黑龙江依兰县依蓝云计算机有限公司(以下简称：依蓝公司)就利用公司旗下成功上线的“咱县打车”APP软件，为考生免费约车，助力高考，赢得了学生和家长以及社会的一致好评!',
                    herf:'查看报道',
                    playerOptions: {
                        //播放速度
                        playbackRates: [0.5, 1.0, 1.5, 2.0], 
                        //如果true,浏览器准备好时开始回放。
                        autoplay: false, 
                        // 默认情况下将会消除任何音频。
                        muted: true, 
                        // 导致视频一结束就重新开始。
                        loop: true, 
                        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                        preload: 'auto', 
                        language: 'zh-CN',
                        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                        aspectRatio: '16:9',
                        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                        fluid: true,
                        sources: [{
                            //类型
                            type: "video/mp4",
                            //url地址
                            src: require('../../assets/PC/video.mp4')
                            
                        }],
                        //你的封面地址
                         poster: require('../../assets/PC/view4.png'), 
                        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                        notSupportedMessage: '此视频暂无法播放，请稍后再试',
                        controlBar: {
                            timeDivider: true,
                            durationDisplay: true,
                            remainingTimeDisplay: false,
                            //全屏按钮
                            fullscreenToggle: true  
                        }
                    },
                }
            }
        }
    },
    mounted () {
        if(this.$route.query.type){
            this.type=this.$route.query.type
        }
        // 检测界面宽度设置left
        if(document.body.clientWidth>1270){
            this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
        }else{
            this.textLeft='left:0px' 
        }
        window.onresize = () => {
            return (() => {
                if(document.body.clientWidth>1270){
                    this.textLeft='left:'+ (document.body.clientWidth-1270)/2 +'px'
                }else{
                    this.textLeft='left:0px'
                }
            })();
        };
    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
    .details{
        padding-top: 70px;
        .detailsPC{
            display: block!important;
            width: 100%;
        }
        .detailsClass{
            display: none!important;
        }
        .bannerH5{
            display: none;
        }
        .banner{
            position: relative;
            width: 100%;
            img{
                width: 100%;
                // float: left;
            }
                .text{
                    position: absolute;
                    left: 12%;
                    top: 50%;
                    transform: translateY(-50%);
                    h2{
                        font-size: 64px;
                        width: 820px;
                        color: #333;
                        font-weight: normal;
                    }
                    p{
                        margin-top: 30px;
                        color: #333;
                        font-size: 24px;
                    }
                }
        }

        .tap{
            border-bottom: 1px solid #e6e6e6;
            /deep/.el-breadcrumb__item{
                padding: 23px 0;
                font-size: 16px;
            }
            /deep/.el-breadcrumb__separator[class*=icon]{
                margin:0 31px;
            }
            /deep/.el-breadcrumb__inner{
                position: relative;
                color: #009cff;
                font-weight: normal;
            }
            /deep/.el-breadcrumb__inner::after{
                content: '';
                position: absolute;
                left: 50%;
                bottom: -23px;
                transform: translateX(-50%);
                width: 32px;
                height: 3px;
                background-color: #009cff;
            }
            /deep/.el-breadcrumb__inner.is-link{
                color: #333;
            }
            /deep/.el-breadcrumb__inner.is-link::after{
                display: none;
            }
        }
        .title{
            text-align: center;
            margin-top: 60px;
            font-size: 36px;
        }
        .con{
            .p{
                display: block;
                text-align: center;
                font-size: 16px;
                margin-top: 22px;
                margin-bottom: 50px;
            }
            .H5p{
                display: none;
            }
            .video-player{
                width: 860px;
                margin: 0 auto 40px;
            }
        }
        .herf{
            cursor: pointer;
            width: 320px;
            margin: 0 auto 60px;
            text-align: center;
            line-height: 48px;
            color: #fff;
            font-size: 16px;
            height: 48px;
            background-color: #009cff;
            border-radius: 4px;
        }
        .main.p{
            text-align: left;
            font-size: 17px;
            margin-bottom: 30px;    text-align: justify;
        }
        .pLine{
            padding-bottom: 70px;
            border-bottom: 1px solid #e6e6e6;
        }
        .transverse{
            width: 100%;
            overflow: hidden;
            margin: 40px 0;
            ul{
                width: 100%;
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                li{
                    width: 33.3%;
                }
            }
        }
        .number{
            .msgH5{
                display: none;
            }
            .title{
                margin-bottom: 60px;
            }
            ul{
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                li{
                    width: 33.3%;
                    text-align: center;
                    min-height: 240px;
                    text-align: center;
                    .num{
                        font-size: 72px;
                        color: #009cff;
                    }
                    .text{
                        font-size: 16px;
                        color: #009cff;
                        margin-top: 20px;
                    }
                    .msg{
                        font-size: 16px;
                        color: #999;
                        margin-top: 20px;
                    }
                }
            }
        }
        
        .media{
            padding-bottom: 70px;
            margin-top: 50px;
            
            ul{
                margin-top: 30px;
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                li{
                    position: relative;
                    width: 32%;
                    height: 240px;
                    background: url(../../assets/PC/media1.png) no-repeat;
                    background-size: 100% 100%;
                    margin-bottom: 48px;
                    span{
                        display: inline-block;
                        position: absolute;
                        font-size: 24px;
                        left: 50%;
                        top: 50%;
                        color: #fff;
                        transform: translate(-50%,-50%);
                        border-bottom: 4px solid transparent;
                    }
                }
                li:hover span{
                    border-bottom: 4px solid #fff;
                }
                li:nth-child(2){
                    background: url(../../assets/PC/media2.png) no-repeat;
                    background-size: 100% 100%;
                }
                li:nth-child(3){
                    background: url(../../assets/PC/media3.png) no-repeat;
                    background-size: 100% 100%;
                }
                li:nth-child(4){
                    background: url(../../assets/PC/media4.png) no-repeat;
                    background-size: 100% 100%;
                }
                li:nth-child(5){
                    background: url(../../assets/PC/media5.png) no-repeat;
                    background-size: 100% 100%;
                }
                li:nth-child(6){
                    background: url(../../assets/PC/media6.png) no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
        .mediaP{
               margin-top: 1.2rem;
        }
        .ibby{
            position: relative;
            margin-bottom: 108px;
            height: 300px;
            background: url(../../assets/PC/bg_ibby.png) no-repeat;
            background-size: 100% 100%;
            box-sizing: border-box;
            padding: 30px 70px 0;
            // margin-bottom: 0;
            .logo{
                width: 156px;
                
            }
            p{
                width: 900px;
                font-size: 16px;
                margin-top:35px;
            }
            .btn{
                
                position: absolute;
                left: 70px;
                bottom: 30px;
                width: 139px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                background-color: #ff3c00;
                border-radius: 4px;
                font-size: 16px;
                color: #fff;
                cursor: pointer;
            }
            .btn::after{
                position: absolute;
                content: '';
                bottom: 14px;
                left: 160px;
                width: 8px;
                height: 16px;
                background: url(../../assets/H5/ibby_ic_arrow.png) no-repeat;
                background-size: 100% 100%;
            }
        }

        .imgText{
            margin-top: 80px;
            padding-bottom: 60px;
            border-bottom:1px solid #e6e6e6;
            ul{
                width: 100%;
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;
                li{
                    position: relative;
                    width: 49%;
                    p{
                        font-size: 17px;
                    }
                    .btn{
                        position: absolute;
                        width: 160px;
                        height: 48px;
                        line-height: 48px;
                        text-align: center;
                        background-color: #009cff;
                        border-radius: 4px;
                        bottom: 0;
                        left: 0;
                        color: #fff;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    // 手机端样式
    #app.H5Class {
        .details{
            padding-top: 0.8rem;
            width: 7.5rem;
            .detailsPC{
                display: none!important;
            }
            .detailsClass{
                display: block!important;
                
            }
            .banner{
                display: none;
            }
            .bannerH5{
                overflow: hidden;
                width: 100%;
                display: block;
                height: 5.6rem;
                background:url(../../assets/H5/duty_img_banner2.png) no-repeat;
                background-size: 100% 100%;
                h3{
                    margin-top: 1.2rem;
                    font-size: 0.6rem;
                    font-weight: 400;
                    padding-left: 1rem;
                }
                h5{
                    margin-top: 0.3rem;
                    padding-left: 1rem;
                    font-size: 0.24rem;
                    font-weight: 400;
                    width: 4.3rem;
                }
            }
            .tap{
                box-sizing: border-box;
                padding-left: 0.4rem;
                /deep/.el-breadcrumb__item{
                    padding:0.4rem 0;
                    font-size: 0.3rem;
                }
                /deep/.el-breadcrumb__item:nth-child(1){
                    display: none;
                }
                /deep/.el-breadcrumb__item{
                    padding:0.4rem 0;
                    font-size: 0.3rem;
                }
                /deep/.el-breadcrumb__item:nth-child(2) .el-breadcrumb__separator[class*=icon]{
                    color: #009cff;
                    margin: 0 0.3rem;
                }
                /deep/.el-breadcrumb__inner::after{
                    bottom: -0.4rem;
                    width: 0.6rem;
                }
                // /deep/.el-breadcrumb__separator[class*=icon]{
                //     display: none;
                // }
            }
            .media{
                margin-top: 00.8rem;
            }
            .con{
                .title{
                    font-size: 0.34rem;
                    margin-bottom: 0.8rem;
                    margin-top: 0.8rem;
                }
                .p{
                    display: none;
                }
                .H5p{
                    display: block;
                    text-align: center;
                    font-size: 0.24rem;
                    margin-top: 0.1rem;
                }
                .video-player{
                    width: 100%;
                    margin-top: 0.6rem;
                }
                .herf{
                    width: 3.2rem;
                    height: 0.8rem;
                    font-size: 0.24rem;
                    line-height: 0.8rem;
                    border-radius: 0.04rem;
                }
                .main.p{
                    display: block;
                    padding: 0 0.4rem;
                    font-size: 0.24rem;
                    text-align: justify;
                }
                .transverse{
                    margin:0;
                    li{
                        width: 100%;
                        img{
                            width: 100%;
                        }
                    }
                }
                .number{
                    li{
                        width: 100%;
                        min-height: 2.2rem;
                        .num{
                            font-size: 1rem;
                        }
                        .text{
                            font-size: 0.24rem;
                            margin-top: 0;
                        }
                    }
                    .msg{
                        display: none;
                    }
                    .msgH5{
                        display: block;
                        text-align: center;
                        font-size: 0.24rem;
                        color: #999;
                    }
                    
                }
                .pLine{
                    padding-bottom:0.8rem !important;
                }
                .media{
                    li{
                        width: 100%;
                        height: 3.2rem;
                        background: url(../../assets/H5/media2.png) no-repeat;
                        background-size: 100% 100%;
                        margin-bottom: 0.3rem;
                        span{
                            font-size: 0.28rem;
                        }
                    }
                    li:nth-child(2){
                        background: url(../../assets/H5/media2.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    li:nth-child(3){
                        background: url(../../assets/H5/media3.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    li:nth-child(4){
                        background: url(../../assets/H5/media4.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    li:nth-child(5){
                        background: url(../../assets/H5/media5.png) no-repeat;
                        background-size: 100% 100%;
                    }
                    li:nth-child(6){
                        background: url(../../assets/H5/media6.png) no-repeat;
                        background-size: 100% 100%;
                    }
                }
                .ibby{
                    background: url(../../assets/H5/bg_ibby.png) no-repeat;
                    background-size: 100% 100%;
                    height: 6.4rem;
                    padding: 0.8rem 0.3rem;
                    margin-bottom:0;
                    p{
                        width: 100%;
                        font-size: 0.24rem;
                        margin-top: 0.6rem;
                    }
                    .btn{
                        text-decoration:underline;
                        width: 2rem;
                        height: 0.8rem;
                        line-height: 0.8rem;
                        font-size:0.3rem ;
                        border-radius: 0.01rem;
                        left: 0.3rem;
                        bottom: 0.8rem;
                    }
                    .btn::after{
                        width: 0.1rem;
                        height: 0.2rem;
                        bottom: 0.3rem;
                        left: 2.4rem;
                    }
                }
                .imgText{
                    margin-top: 0.6rem;
                    li{
                        width: 100%;
                        p{
                            font-size: 0.24rem;
                            padding: 0 0.4rem 0.7rem;
                            line-height: 1.6;
                            text-align: justify;
                        }
                        img{
                            max-width: 100%;
                            height: 4rem;
                            padding-bottom:1rem ;
                            padding-top: 0.6rem;
                        }
                        .btn{
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: -6.1rem;
                            width: 3.2rem;
                            height: 0.8rem;
                            line-height: 0.8rem;
                            font-size: 0.24rem;
                        }
                    }
                }
            }
        }
    }
</style>